<template lang="pug">
  .lh-admin-controls-panel(
    data-jest="admin-panel"
  )
    button.lh-admin-controls-panel__views(
      :class="computedViewsClass"
      data-jest="views-restore"
      @click="onClickRestoreButton"
    )
      include ../_assets/eye.svg
      span.lh-admin-controls-panel__views-count(
        data-jest="views"
      ) {{ viewsCount }}
      span.lh-admin-controls-panel__views-restore
        include ../_assets/restore.svg
    .lh-admin-controls-panel__edit
      a.lh-admin-controls-panel__edit-link(
        :href="editLinkHref"
        data-jest="edit-link"
        target="_blank"
        name="Редактировать статью"
      )
        include ../_assets/pencil-icon.svg
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  export default defineNuxtComponent({
    name: 'AdminControlsPanel',
    props: {
      editLinkHref: {
        type: String as PropType<string>,
        required: true,
      },
      viewsCount: {
        type: Number as PropType<number>,
        required: true,
      },
      loading: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
    },
    computed: {
      computedViewsClass(): 'lh-admin-controls-panel__views_loading' | '' {
        return this.loading ? 'lh-admin-controls-panel__views_loading' : '';
      },
    },
    methods: {
      onClickRestoreButton() {
        const isNotLoading = !this.loading;

        if (isNotLoading) {
          /**
           * Генерируется при клике на кнопку "обновить просмотры"
           */
          this.$emit('click-on-restore-button');
        }
      },
    },
  });
</script>

<style lang="scss" src="./AdminControlsPanel.scss"></style>
